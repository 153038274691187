import format from "date-fns-tz/format";

const shortTime = new Intl.DateTimeFormat("en-US", { timeStyle: "short" });
const militaryTime = new Intl.DateTimeFormat("en-GB", { timeStyle: "short" });
const singleMilitaryDateTime = new Intl.DateTimeFormat("en-GB", {
  dateStyle: "medium",
  timeStyle: "short",
});
const singleShortDateTime = new Intl.DateTimeFormat("en-US", {
  dateStyle: "medium",
  timeStyle: "short",
});

export function formatShortTime(start, end, formatter = shortTime) {
  return `${formatter.format(start)} - ${formatter.format(end)}`;
}

export function formatMilitaryTime(start, end, formatter = militaryTime) {
  return `${formatter.format(start)} - ${formatter.format(end)}`;
}

export function formatDuration(start, end, milTime = false) {
  if (typeof start !== "object") {
    start = new Date(start);
  }
  if (typeof end !== "object") {
    end = new Date(end);
  }
  if (milTime) {
    return formatMilitaryTime(start, end);
  } else {
    return formatShortTime(start, end);
  }
}

export function formatSingleTime(date, milTime = false) {
  if (typeof date !== "object") {
    date = new Date(date);
  }
  let output;
  if (milTime) {
    output = `${singleMilitaryDateTime.format(date)}`;
  } else {
    output = `${singleShortDateTime.format(date)}`;
  }
  return output;
}

const pad = (num) => {
  return (num < 10 ? "0" : "") + num;
};

export function padTime(time) {
  const timeArray = time.split(":");
  var i = 0;
  while (i < timeArray.length) {
    timeArray[i] = String(pad(Number(timeArray[i])));
    i++;
  }
  return timeArray.join(":");
}

export function toIsoDate(date) {
  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate())
  );
}

export function getLocalIsoOffset() {
  //"2024-02-05T12:00:00-04:00"
  const tzo = -new Date().getTimezoneOffset();
  const diff = tzo >= 0 ? "+" : "-";
  return (
    diff + pad(Math.floor(Math.abs(tzo) / 60)) + ":" + pad(Math.abs(tzo) % 60)
  );
}
/**
 * Handles date values from the datePickers in the importTimeOff form
 * @param {string | Date} date currently selected value
 * @returns {string} ISO 8061 formatted string to be sent to the backend
 */
export function transformDate(date) {
  let transformedDate;
  if (typeof date === "object") {
    transformedDate = toIsoDate(date);
  } else {
    const dateFromString = new Date(date);
    transformedDate = toIsoDate(dateFromString);
  }
  return transformedDate;
}

export function formatTime(date) {
  const hours24 = date.getHours();
  const hours = pad(hours24 % 12 || 12);
  const minutes = pad(date.getMinutes());
  const ampm = hours24 < 12 ? "AM" : "PM";
  return `${hours}:${minutes} ${ampm}`;
}

export function combineDateAndTime(date, time) {
  if (date && time) {
    let updatedDate = `${toIsoDate(date)}T${time}:00`;
    return updatedDate;
  } else {
    console.error("Date and time must be provided to combineDateAndTime");
    throw new Error("Missing parameters");
  }
}
