import React, { forwardRef, useState } from "react";
import {
  Typography,
  Grid,
  IconButton,
  Button,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import DateFnsUtils from "@date-io/date-fns";
import CloseIcon from "@mui/icons-material/Close";
import { format, eachDayOfInterval } from "date-fns";
import { useMutation, useQuery } from "@apollo/client";
import { CANCEL_EMPLOYEE_TIME_OFF } from "../../api/gqlQueries";
import { updateTimeOffEvent, updateUnavailabilityEvent } from "./UpdateEvents";
import { ToastUtility } from "@syncfusion/ej2-react-notifications";
import { userVar } from "../../cache";

let toastObj;

function toastShow(content, type) {
  toastObj = ToastUtility.show({
    content: content,
    icon:
      type === "Warning"
        ? "e-warning toast-icons"
        : type === "Success"
          ? "e-success toast-icons"
          : "e-error toast-icons",
    timeOut: 3000,
    position: { X: "Center", Y: "Top" },
    showCloseButton: true,
    cssClass:
      type === "Warning"
        ? "e-toast-warning"
        : type === "Success"
          ? "e-toast-success"
          : "e-toast-danger",
  });
}
const useStyles = makeStyles((theme) => ({
  input: {
    minWidth: 138,
    maxWidth: 225,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  wideInput: {
    width: 575,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textField: {
    width: 50,
  },
  timeField: {
    width: 120,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  button: {
    width: 75,
  },
  error: {
    color: theme.palette.primary.main,
  },
  helperText: {
    color: theme.palette.text.secondary,
  },
  dateError: {
    color: theme.palette.primary.main,
    width: 225,
    marginTop: -4,
    paddingLeft: 14,
    paddingRight: 14,
  },
}));

/* retrieve time off on a given date and lets user edit the form
   start date and end date can span over a few days.
   user can select a part of the day or user can set the event to an all day event.
   user can delete their time off requests
*/

const EditTimeOffRequestForm = forwardRef((props, ref) => {
  const classes = useStyles();
  const user = userVar();

  const {
    closeDialog,
    timeOffRequestToEdit,
    notifyDevelopers,
    environment,
    timeOffRequestsRefetch,
    SetAllDateTimeOffs,
    availabilityTypes,
    selectedOffice,
  } = props;
  const eventId = timeOffRequestToEdit.eventId;

  const [startDate, setStartDate] = useState(timeOffRequestToEdit.start);
  const [endDate, setEndDate] = useState(timeOffRequestToEdit.end);
  const [startTime, setStartTime] = useState(
    !timeOffRequestToEdit.isAllDay
      ? format(timeOffRequestToEdit.start, "HH:mm")
      : "",
  );
  const [endTime, setEndTime] = useState(
    !timeOffRequestToEdit.isAllDay
      ? format(timeOffRequestToEdit.end, "HH:mm")
      : "",
  );
  const [version, setVersion] = useState(timeOffRequestToEdit.version);
  const [isAllDay, setIsAllDay] = useState(timeOffRequestToEdit.isAllDay);
  const [workHours, setWorkHours] = useState(timeOffRequestToEdit.workHours);
  const [type, setType] = useState(
    ref ? timeOffRequestToEdit.type.id : timeOffRequestToEdit.typeId,
  );
  const [comment, setComment] = useState("");
  const [error, setError] = useState("");
  const [startError, setStartError] = useState("");
  const [endError, setEndError] = useState("");

  let allDateTimeOffs;
  let userSoft;
  let timeOffEventsNotCurrentRequest;
  let showCancel;
  let allowEdits = false;
  if (ref) {
    showCancel =
      user.id == timeOffRequestToEdit.employee.id &&
      timeOffRequestToEdit.status === "REQUESTED";
    allDateTimeOffs =
      ref.eventsData &&
      ref.eventsData.length > 0 &&
      ref.eventsData.filter((event) => event.eventType === "EMPLOYEETIMEOFF");

    userSoft =
      ref.eventsData &&
      ref.eventsData.length > 0 &&
      ref.eventsData.filter(
        (event) =>
          event.eventType === "OFFPREFERENCES" &&
          event.employee.id === userSoft.id,
      );

    timeOffEventsNotCurrentRequest =
      allDateTimeOffs &&
      allDateTimeOffs.length > 0 &&
      allDateTimeOffs.filter((event) => event.eventId !== eventId);
  } else {
    showCancel =
      user.id == timeOffRequestToEdit.employeeIds[0] &&
      timeOffRequestToEdit.status === "REQUESTED";
  }

  let invalidDates = [];
  userSoft &&
    userSoft.length > 0 &&
    userSoft.forEach((request) => {
      invalidDates.push(request.start);
    });

  timeOffEventsNotCurrentRequest &&
    timeOffEventsNotCurrentRequest.length > 0 &&
    timeOffEventsNotCurrentRequest.forEach((request) => {
      const arrayOfTimeOff = eachDayOfInterval({
        start: request.start,
        end: request.end,
      });
      invalidDates = [...invalidDates, ...arrayOfTimeOff];
    });

  invalidDates = invalidDates.map((date) => format(date, "MM/dd/yyyy"));

  // const [updateRequest] = useMutation(UPDATE_EMPLOYEE_AVAILABILITY, {
  //   onCompleted(d) {
  //     if (ref) {
  //       updateUnavailabilityEvent(
  //         ref.current,
  //         d.updateEmployeeAvailability.employeeAvailability,
  //         timeOffRequestToEdit,
  //         selectedOffice.name,
  //       );
  //     }
  //     toastShow("Edit Time Off Request successfully updated", "Success");
  //     closeDialog();
  //   },
  //   onError(error) {
  //     console.log(error);
  //     toastShow(
  //       "Unable to edit time off request. Please check dates and try again.",
  //       "Error",
  //     );
  //     notifyDevelopers({
  //       variables: {
  //         message:
  //           "Error on UPDATE_EMPLOYEE_AVAILABILITY Mutation. Environment: " +
  //           environment +
  //           ". Graphql " +
  //           error,
  //       },
  //     });
  //   },
  // });

  const [deleteRequest] = useMutation(CANCEL_EMPLOYEE_TIME_OFF, {
    update(cache, { data: { cancelEmployeeTimeOff } }) {
      cache.evict({
        id: `EmployeeTimeOffNode:${cancelEmployeeTimeOff.result.canceledTimeOff.id}`,
      });
    },
    onCompleted(d) {
      if (ref) {
        updateTimeOffEvent(
          ref.current,
          d.cancelEmployeeTimeOff.result.canceledTimeOff,
          timeOffRequestToEdit,
          selectedOffice.name,
        );
      }

      toastShow("Edit Time Off Request successfully deleted", "Success");
      closeDialog();
    },
    onError(error) {
      console.log(error);
      toastShow(
        "Unable to delete time off request. Please try again.",
        "Error",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on UPDATE_EMPLOYEE_AVAILABILITY Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const handleSubmit = () => {
    let newEvent = {
      type: type,
      workHours: workHours,
      firstday: format(startDate, "yyyy-MM-dd"),
      lastday: format(endDate, "yyyy-MM-dd"),
      comment: comment,
    };

    if (isAllDay === false && startTime && endTime) {
      newEvent.startTime = `${startTime}:00`;
      newEvent.endTime = `${endTime}:00`;
    }

    updateRequest({
      variables: {
        id: eventId,
        input: {
          ...newEvent,
        },
      },
    });
  };

  const handleDeleteRequest = () => {
    deleteRequest({
      variables: {
        id: parseInt(eventId),
        version: parseInt(version),
      },
    });
  };

  const checkInvalid = (date) => {
    const formatted = format(date, "MM/dd/yyyy");
    return invalidDates.includes(formatted);
  };

  const eligibleToSave = Boolean(
    !checkInvalid(startDate) && !checkInvalid(endDate) && !endDate < startDate,
  );

  const handleUpdateStart = (date) => {
    if (date && !isNaN(date.getTime())) {
      const invalid = checkInvalid(date);
      if (!invalid) {
        setStartDate(date);
        setStartError("");
        if (date > endDate) {
          setEndDate(date);
        }
      } else {
        setStartError(
          "You must choose a date that does not already have a request",
        );
      }
    }
  };

  const handleUpdateEnd = (date) => {
    if (date && !isNaN(date.getTime())) {
      const invalid = checkInvalid(date);
      if (!invalid) {
        setEndDate(date);
        setEndError("");
      } else {
        setEndError(
          "You must choose a date that does not already have a request",
        );
      }
    }
  };

  const handleWorkHoursChange = (event) => {
    setWorkHours(event.target.value);
  };

  const handleIsAllDayChange = (e) => {
    setIsAllDay(e.target.checked);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid container direction="column" spacing={2}>
        <Grid item container justifyContent="space-between">
          <Grid data-testid="edit-time-off-form-title" item>
            {allowEdits ? (
              <Typography variant="h3">Edit Time Off Request</Typography>
            ) : (
              <Typography variant="h3">Time Off Request</Typography>
            )}
          </Grid>
          <Grid item>
            <IconButton
              aria-label="close"
              color="secondary"
              size="small"
              onClick={closeDialog}
              data-testid="closeEditTimeOffFormShift"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item>
          <FormControlLabel
            data-testid="allDaySwitchEditTimeOffFormShift"
            control={
              <Switch
                checked={isAllDay}
                onChange={handleIsAllDayChange}
                name="isAllDay"
                color={isAllDay ? "primary" : "secondary"}
                disabled={!allowEdits}
              />
            }
            label="All Day"
          />
        </Grid>
        <Grid item container justifyContent="flex-start" spacing={2}>
          <Grid item>
            <InputLabel htmlFor="start-date">
              <Typography variant="h6">Start Date:</Typography>
            </InputLabel>
            <DatePicker
              disableToolbar
              autoOk
              variant="inline"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              id="start-date"
              shouldDisableDate={checkInvalid}
              value={startDate}
              onChange={handleUpdateStart}
              className={classes.input}
              renderInput={(props) => <TextField {...props} />}
              data-testid="startDateEditTimeOffFormShift"
              disabled={!allowEdits}
            />
            {startError && (
              <Typography variant="body2" className={classes.dateError}>
                {startError}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <InputLabel htmlFor="end-date">
              <Typography variant="h6">End Date:</Typography>
            </InputLabel>
            <DatePicker
              disableToolbar
              autoOk
              variant="inline"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              id="end-date"
              shouldDisableDate={checkInvalid}
              minDate={startDate}
              minDateMessage="Date should not be before start date"
              value={endDate}
              onChange={handleUpdateEnd}
              className={classes.input}
              disabled={!allowEdits}
              renderInput={(props) => <TextField {...props} />}
              data-testid="endDateEditTimeOffFormShift"
            />
            {endError && (
              <Typography variant="body2" className={classes.dateError}>
                {endError}
              </Typography>
            )}
          </Grid>
        </Grid>
        {isAllDay === false && (
          <Grid item container spacing={1} alignItems="center">
            <Grid item>
              <InputLabel htmlFor="start-time">
                <Typography variant="h6">Start Time:</Typography>
              </InputLabel>
              <TextField
                id={"start-time"}
                name={"startTime"}
                variant="outlined"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
                type="time"
                disabled={isAllDay || !allowEdits}
                className={classes.timeField}
                data-testid="startTimeEditTimeOffFormShift"
              />
            </Grid>
            <Grid item>
              <InputLabel htmlFor="end-time">
                <Typography variant="h6">End Time:</Typography>
              </InputLabel>
              <TextField
                id={"end-time"}
                name={"endTime"}
                variant="outlined"
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
                type="time"
                disabled={isAllDay || !allowEdits}
                className={classes.timeField}
                data-testid="endTimeEditTimeOffFormShift"
              />
            </Grid>
          </Grid>
        )}
        <Grid item container spacing={1} alignItems="center">
          <Grid item>
            <InputLabel htmlFor="type">
              <Typography variant="h6">Type of Request:</Typography>
            </InputLabel>
          </Grid>
          <Grid item>
            <Select
              id="type"
              name="type"
              variant="outlined"
              type="number"
              value={type}
              className={classes.input}
              onChange={(e) => setType(e.target.value)}
              data-testid="requestTypeEditTimeOffFormShift"
              disabled={!allowEdits}
            >
              {availabilityTypes && availabilityTypes.length > 0
                ? availabilityTypes.map((type, index) => (
                    <MenuItem key={index} value={type.id}>
                      {type.name}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </Grid>
        </Grid>
        <Grid item container spacing={1} alignItems="center">
          <Grid item>
            <InputLabel htmlFor="work-hours">
              <Typography variant="h6">Hours Used:</Typography>
            </InputLabel>
          </Grid>
          <Grid item>
            <TextField
              id="work-hours"
              name="work-hours"
              variant="outlined"
              type="number"
              value={workHours}
              onChange={handleWorkHoursChange}
              data-testid="workhoursEditTimeOffFormShift"
              disabled={!allowEdits}
            />
          </Grid>
        </Grid>
        <Grid item>
          <InputLabel htmlFor="comment">
            <Typography variant="h6">Comment:</Typography>
          </InputLabel>
          <TextField
            id="comment"
            name="comment"
            variant="outlined"
            value={comment}
            className={classes.wideInput}
            onChange={(e) => setComment(e.target.value)}
            data-testid="commentEditTimeOffFormShift"
            disabled={!allowEdits}
          />
        </Grid>
        <Grid item>
          {error && <Typography className={classes.error}>{error}</Typography>}
        </Grid>
        {showCancel && (
          <Grid item container justifyContent="flex-end" spacing={2}>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                onClick={handleDeleteRequest}
                data-testid="delete-time-off-button"
              >
                Delete
              </Button>
            </Grid>
            {/* <Grid item>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleSubmit}
                disabled={!eligibleToSave || workHours === 0}
                data-testid="saveTimeOffFormShift"
              >
                Save
              </Button>
            </Grid> */}
          </Grid>
        )}
      </Grid>
    </LocalizationProvider>
  );
});

export default EditTimeOffRequestForm;
